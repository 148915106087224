define('ember-calendar/models/calendar', ['exports', 'ember', 'moment', 'ember-calendar/models/time-slot', 'ember-calendar/models/day', 'ember-calendar/models/occurrence-proxy'], function (exports, _ember, _moment, _timeSlot, _day, _occurrenceProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Object.extend({
    dayEndingTime: null,
    dayStartingTime: null,
    occurrences: null,
    startingDate: null,
    startFromDate: false,
    timeSlotDuration: null,
    timeZone: null,
    occurrencePreview: null,

    isInCurrentWeek: _ember.default.computed('week', '_currentWeek', function () {
      return this.get('week').isSame(this.get('_currentWeek'));
    }),

    timeSlots: _ember.default.computed('timeZone', 'dayStartingTime', 'dayEndingTime', 'timeSlotDuration', function () {
      return _timeSlot.default.buildDay({
        timeZone: this.get('timeZone'),
        startingTime: this.get('dayStartingTime'),
        endingTime: this.get('dayEndingTime'),
        duration: this.get('timeSlotDuration')
      });
    }),

    days: _ember.default.computed(function () {
      return _day.default.buildWeek({ calendar: this });
    }),

    week: _ember.default.computed('startFromDate', 'startingTime', 'timeZone', function () {
      if (this.get('startFromDate')) {
        return (0, _moment.default)(this.get('startingTime')).tz(this.get('timeZone')).startOf('day');
      } else {
        return (0, _moment.default)(this.get('startingTime')).tz(this.get('timeZone')).startOf('isoWeek');
      }
    }),

    _currentWeek: _ember.default.computed('timeZone', function () {
      return (0, _moment.default)().tz(this.get('timeZone')).startOf('isoWeek');
    }),

    initializeCalendar: _ember.default.on('init', function () {
      if (this.get('startingTime') == null) {
        this.goToCurrentWeek();
      }
    }),

    createOccurrence: function (options) {
      var content = _ember.default.merge({
        endsAt: (0, _moment.default)(options.startsAt).add(this.get('defaultOccurrenceDuration')).toDate(),
        title: this.get('defaultOccurrenceTitle')
      }, options);

      return _occurrenceProxy.default.create({
        calendar: this,
        content: _ember.default.Object.create(content)
      });
    },

    navigateWeek: function (index) {
      this.set('startingTime', (0, _moment.default)(this.get('startingTime')).add(index, 'weeks'));
    },

    goToCurrentWeek: function () {
      this.set('startingTime', (0, _moment.default)());
    }
  });
});