define('ember-calendar/models/component-calendar', ['exports', 'ember', 'ember-calendar/macros/computed-moment', 'ember-calendar/macros/computed-duration', 'ember-calendar/models/calendar', 'ember-calendar/models/occurrence-proxy'], function (exports, _ember, _computedMoment, _computedDuration, _calendar, _occurrenceProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _calendar.default.extend({
    component: null,
    timeZone: _ember.default.computed.oneWay('component.timeZone'),
    startFromDate: _ember.default.computed.readOnly('component.startFromDate'),
    startingTime: (0, _computedMoment.default)('component.startingDate'),
    dayStartingTime: (0, _computedDuration.default)('component.dayStartingTime'),
    dayEndingTime: (0, _computedDuration.default)('component.dayEndingTime'),
    timeSlotDuration: (0, _computedDuration.default)('component.timeSlotDuration'),

    defaultOccurrenceTitle: _ember.default.computed.oneWay('component.defaultOccurrenceTitle'),

    defaultOccurrenceDuration: (0, _computedDuration.default)('component.defaultOccurrenceDuration'),

    occurrences: _ember.default.computed('component.occurrences.[]', function () {
      let newOccurences = _ember.default.A();

      this.get('component.occurrences').forEach(occurrence => {
        newOccurences.pushObject(_occurrenceProxy.default.create({ calendar: this, content: occurrence }));
      });

      return newOccurences;
    })
  });
});