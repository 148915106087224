define('ember-calendar/components/as-calendar/time-zone-select', ['exports', 'moment', 'ember', 'ember-calendar/models/time-zone-option'], function (exports, _moment, _ember, _timeZoneOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    classNameBindings: [':as-calendar-time-zone-select', 'showResults:open'],
    tagName: 'section',

    defaultQuery: '',
    showResults: false,
    inputQuery: '',
    query: '',
    value: null,
    options: null,
    showSearch: true,

    selectedOptionAbbreviation: _ember.default.computed.oneWay('selectedOption.abbreviation'),

    arrangedOptions: _ember.default.computed('_options.@each.{description,value}', 'selectedOption.value', 'showSearch', '_query', function () {
      if (this.get('showSearch')) {
        let regexp = new RegExp(this.get('_query'), 'i');

        return this.get('_options').filter(option => {
          return option.get('description').match(regexp) || option.get('value') === this.get('selectedOption.value');
        });
      } else {
        return this.get('_options');
      }
    }),

    selectedOption: _ember.default.computed('value', function () {
      let value = this.get('value');

      if (value) {
        return _timeZoneOption.default.create({ value: value });
      }
    }),

    _query: _ember.default.computed('query', 'defaultQuery', function () {
      let query = this.get('query');

      if (_ember.default.isEmpty(query)) {
        return this.get('defaultQuery');
      } else {
        return query;
      }
    }),

    _options: _ember.default.computed('options', function () {
      if (_ember.default.isPresent(this.get('options'))) {
        return this.get('options');
      } else {
        return _moment.default.tz.names().map(function (timeZoneName) {
          return _timeZoneOption.default.create({ value: timeZoneName });
        });
      }
    }),

    _setQuery: function (value) {
      this.set('query', value);
    },

    actions: {
      inputQueryChanged: function (value) {
        _ember.default.run.debounce(this, this._setQuery, value, 200);
      }
    }
  });
});