define('ember-calendar/macros/computed-moment', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (dependentKey) {
    return computed(dependentKey, {
      get() {
        var dependentValue = this.get(dependentKey);

        if (dependentValue != null) {
          return (0, _moment.default)(dependentValue);
        } else {
          return null;
        }
      },

      set(_, value) {
        if (value != null) {
          this.set(dependentKey, value.toDate());
        } else {
          this.set(dependentKey, null);
        }

        return value;
      }
    });
  };

  const { computed } = _ember.default;
});