define('ember-calendar/components/as-calendar/timetable/content', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    classNameBindings: [':as-calendar-timetable-content'],
    attributeBindings: ['_style:style'],

    days: _ember.default.computed.oneWay('model.days'),
    model: null,
    timeSlotDuration: _ember.default.computed.oneWay('model.timeSlotDuration'),
    timeSlots: _ember.default.computed.oneWay('model.timeSlots'),
    timetable: null,

    timeSlotStyle: _ember.default.computed('timeSlotHeight', function () {
      return _ember.default.String.htmlSafe(`height: ${this.get('timeSlotHeight')}px`);
    }),

    dayWidth: _ember.default.computed(function () {
      if (this.get('_wasInserted')) {
        return this.$().width() / this.get('days.length');
      } else {
        return 0;
      }
    }).volatile(),

    _wasInserted: false,

    _style: _ember.default.computed('timeSlotHeight', 'timeSlots.length', function () {
      return _ember.default.String.htmlSafe(`height: ${this.get('timeSlots.length') * this.get('timeSlotHeight')}px;`);
    }),

    _setWasInserted: _ember.default.on('didInsertElement', function () {
      this.set('_wasInserted', true);
    }),

    _registerWithParent: _ember.default.on('init', function () {
      this.set('timetable.contentComponent', this);
    }),

    _selectTime: _ember.default.on('click', function (event) {
      var offset = this.$().offset();
      var offsetX = event.pageX - Math.floor(offset.left);
      var offsetY = event.pageY - Math.floor(offset.top);

      var dayIndex = Math.floor(offsetX / this.get('dayWidth'));
      var timeSlotIndex = Math.floor(offsetY / this.get('timeSlotHeight'));
      var day = this.get('days').objectAt(dayIndex);

      var timeSlot = this.get('timeSlots').objectAt(timeSlotIndex);

      this.attrs.onSelectTime((0, _moment.default)(day.get('value')).add(timeSlot.get('time')));
    })
  });
});