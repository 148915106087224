define('ember-calendar/components/as-calendar/occurrence', ['exports', 'ember', 'ember-calendar/macros/computed-duration'], function (exports, _ember, _computedDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    attributeBindings: ['_style:style'],
    classNameBindings: [':as-calendar-occurrence'],
    tagName: 'article',

    model: null,
    timeSlotDuration: null,
    timeSlotHeight: null,
    title: _ember.default.computed.oneWay('model.title'),
    content: _ember.default.computed.oneWay('model.content'),
    day: _ember.default.computed.oneWay('model.day'),
    computedTimeSlotDuration: (0, _computedDuration.default)('timeSlotDuration'),

    titleStyle: _ember.default.computed('timeSlotHeight', function () {
      return _ember.default.String.htmlSafe(`line-height: ${this.get('timeSlotHeight')}px;`);
    }),

    _duration: _ember.default.computed.oneWay('model.duration'),
    _startingTime: _ember.default.computed.oneWay('model.startingTime'),
    _dayStartingTime: _ember.default.computed.oneWay('day.startingTime'),

    _occupiedTimeSlots: _ember.default.computed('_duration', 'computedTimeSlotDuration', function () {
      return this.get('_duration').as('ms') / this.get('computedTimeSlotDuration').as('ms');
    }),

    _height: _ember.default.computed('_occupiedTimeSlots', function () {
      return this.get('timeSlotHeight') * this.get('_occupiedTimeSlots');
    }),

    _top: _ember.default.computed('_startingTime', '_dayStartingTime', 'computedTimeSlotDuration', 'timeSlotHeight', function () {
      return this.get('_startingTime').diff(this.get('_dayStartingTime')) / this.get('computedTimeSlotDuration').as('ms') * this.get('timeSlotHeight');
    }),

    _style: _ember.default.computed('_height', '_top', function () {
      return _ember.default.String.htmlSafe(`top: ${this.get('_top')}px;
            height: ${this.get('_height')}px;`);
    }),

    _stopPropagation: _ember.default.on('click', function (event) {
      event.stopPropagation();
    })
  });
});